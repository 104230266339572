import moment from 'moment';

(function($) {
  blist.namespace.fetch('blist.internal');

  blist.internal.fetchDomainList = function($element, options) {
    var staticOptions = {
      dontMatch: ['id', 'parentDomainId'],
      highlightMatches: true,
      ignoreCase: true,
      onComplete: options.onComplete,
      staticData: blist.internal.domains,
      valueFunction: function(datum) { return datum.cname; }
    };

    if (_.isUndefined(staticOptions.staticData)) {
      $element.addClass('loading');
      $element.find('input').attr('readonly', 'readonly');
      $.ajax({
        url: '/internal/domains_summary.json',
        dataType: 'json',
        success: function(domainList) {
          blist.internal.domains = domainList;
          $.extend(staticOptions, {
            staticData: blist.internal.domains
          });
          $element.removeClass('loading');
          $element.find('input').prop('readonly', false);
          $element.find('input').awesomecomplete(staticOptions);
        }
      });
    } else {
      $element.find('input').awesomecomplete(staticOptions);
    }
  };

  var $domainCenter = $('.domainCompletion');
  var $youCompleteMe = $domainCenter.find('#domainSelect');

  $youCompleteMe.on('focus', function() {
    blist.internal.fetchDomainList($domainCenter, {
      onComplete: completelyAwesome
    });
  });

  var completelyAwesome = function(domain) {
    var url;
    if (domain.organizationId) {
      url = `/internal/orgs/${domain.organizationId}/domains/${domain.cname}`;
    } else {
      url = `/internal/domains/${domain.cname}`;
    }
    window.location = url;
  };

  $domainCenter.find('ul').css('width', '90%');

  $('.expandable').each(function() {
    var $expandable = $(this);
    var $panel = $expandable.find('> div');
    $expandable.find('> .headerLink').on('click', function(evt) {
      if (!$(evt.target).is('a')) {
        $(this).toggleClass('collapsed');
        $panel.toggleClass('collapsed');
      }
    });
  });

  $('#domain_alias').on('change blur', function() {
    var domainAlias = $('#domain_alias').val();

    function showAliasValidity(result) {
      var submitButton = $('.updateAliasesForm input[type="submit"]');

      if (result.valid) {
        $('.invalid_domain_alias').hide();
      } else {
        $('.invalid_domain_alias').show();
      }

      if (result.available) {
        $('.unavailable_domain_alias').hide();
      } else {
        $('.unavailable_domain_alias').show();
      }

      if (result.valid && result.available) {
        submitButton.attr('disabled', false);
      } else {
        submitButton.attr('disabled', true);
      }
    }

    if (!_.isEmpty(domainAlias)) {
      $.ajax('/internal/check_cname_or_alias_validity?alias=' + domainAlias, {
        method: 'GET',
        dataType: 'json',
        success: showAliasValidity
      });
    } else {
      showAliasValidity({valid: false, available: true});
    }
  });

  $('#domain_cname, #domain_name').on('change blur', function() {
    var cName = $('#domain_cname').val();

    function showCnameValidity(result) {
      var submitButton = $('#create_domain_form input[type="submit"]');

      if (result.valid) {
        $('.invalid_cname').hide();
      } else {
        $('.invalid_cname').show();
      }

      if (result.available) {
        $('.cname_not_available').hide();
      } else {
        $('.cname_not_available').show();
      }

      if (!_.isEmpty($('#domain_name').val())) {
        $('.invalid_domain_name').hide();
      } else {
        $('.invalid_domain_name').show();
      }

      if (result.valid && result.available) {
        submitButton.attr('disabled', false);
      } else {
        submitButton.attr('disabled', true);
      }
    }

    if (!_.isEmpty(cName)) {
      $.ajax('/internal/check_cname_or_alias_validity?cname=' + cName, {
        method: 'GET',
        dataType: 'json',
        success: showCnameValidity
      });
    } else {
      showCnameValidity({valid: false, available: true});
    }
  });

  if ($('.organizationLink').exists()) {
    const fillTheBlanks = function(orgList) {
      // Pick exactly one currentOrg.
      const currentOrgId = parseInt($('.organizationLink.currentOrg:eq(0)').text());
      // Populate the organization list dropdown on show_domain.
      const $select = $('.organizationList');
      _.map(orgList, function(org) {
        let selected = false;
        if (org.id === currentOrgId) {
          selected = true;
        }
        $select.append($.tag2({
          _: 'option',
          value: org.id,
          selected: selected,
          contents: org.name
        }));
      });

      // Rewrite all the text for organizationLinks with org names.
      $('.organizationLink').each(function() {
        const $this = $(this);
        const linkedOrgId = parseInt($this.text());
        const linkedOrg = _.find(orgList, (org) => org.id === linkedOrgId);
        if (linkedOrg) { $this.text(linkedOrg.name); }
      });
    };
    $.ajax({
      url: '/internal/organization_list.json',
      dataType: 'json',
      success: fillTheBlanks
    });
  }

  $.fn.formatWithMoment = function(format) {
    var text = this.text();
    var asMoment = moment(text);
    format = format || 'llll Z';

    if (asMoment.isValid()) {
      this.text(asMoment.locale('en').format(format));
    }
  };
})(jQuery);
